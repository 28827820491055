import React from 'react';

import Helmet from 'react-helmet';
import Layout from 'components/layout';
import SEO from 'components/seo';
import { Global } from '@emotion/core';
import Content1 from "../sections/home5/Content1";
import { injectIntl, IntlContextConsumer } from 'gatsby-plugin-intl';

const Features = ({intl}) => (
  <>
    <Global
      styles={theme => ({
        body: {
          fontFamily: theme.fonts.body,
        }
      })}
    />
    <Layout>
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) => (
          <SEO
            title={intl.formatMessage({ id: "features" })}
            lang={currentLocale}
            description={intl.formatMessage({ id: "features_description" })}
          />
        )}
      </IntlContextConsumer>
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) => (
          <Helmet>
            <link rel="alternate" hrefLang="x-default" href="https://couponise.app/features/" />
            {languages.map(language => {
              if(language !== intl.defaultLocale) {
                return (
                  <link key={language} rel="alternate" hrefLang={`${language}`} href={`https://couponise.app/${language}/features/`} />
                )
              }
            })}
         </Helmet>
        )}
      </IntlContextConsumer>
      <div className="pt-23 pt-md-26 pt-lg-31 pb-13 pb-md-18 pb-lg-23">
        <Content1></Content1>
      </div>
    </Layout>
  </>
);

export default injectIntl(Features);
