import React from "react";
import Fade from "react-reveal";
import { FormattedMessage } from "gatsby-plugin-intl"

import TransparentPhone from "../../assets/image/home-6/png/mobile-frame.png";
import Step from "../../assets/image/gifs/step1_fr10_loss_180.gif";

const Content1 = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container">
        {/* Section title */}
        <div className="row align-items-center justify-content-between">

            <div
              className="col-lg-4 offset-lg-1 col-md-4 col-xs-5"
            >
              <div style={{ position: 'relative' }}>
                <Fade left delay={500}>
                  <img className="w-100" src={Step} style={{ position: 'absolute', padding: '0 10px', top: '15px', borderRadius: '30px', overflow: 'hidden', height: '95%' }} />
                  <img className="w-100" src={TransparentPhone} alt="" />
                </Fade>
              </div>
            </div>

          <Fade right delay={500}>
            <div className="col-xl-6 col-lg-7 col-md-6 pt-lg-23 pb-lg-18">
              <h3 className="font-size-11 mb-0 mb-10 pr-xs-18 pr-md-0">
                <FormattedMessage id="features_title" />
              </h3>
              <p className="font-size-7 mb-0 pr-xs-15 pr-md-0 pl-xl-2 mt-7 mt-lg-0 pr-lg-18">
                <FormattedMessage id="features_description" />
              </p>
            </div>
          </Fade>
        </div>
        {/* End Section title */}
        {/* Services */}
        <div className="pt-lg-9">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-xs-8">
              <div
                className="pt-10 pt-lg-18"
                data-aos="fade-up"
                data-aos-delay={300}
              >
                <div className="circle-60 bg-sunset text-white font-size-7">
                  <i className="fa fa-tachometer-alt" />
                </div>
                <div className="mt-9">
                  <h4 className="font-size-8 mb-6"><FormattedMessage id="simple_quick" /></h4>
                  <p className="font-size-5 mb-0 pr-xl-8">
                    <FormattedMessage id="simple_quick_text" />
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-xs-8">
              <div
                className="pt-10 pt-lg-18"
                data-aos="fade-up"
                data-aos-delay={500}
              >
                <div className="circle-60 bg-bluewood text-white font-size-7">
                  <i className="fa fa-chart-bar" />
                </div>
                <div className="mt-9">
                  <h4 className="font-size-8 mb-6"><FormattedMessage id="detailed_analytics" /></h4>
                  <p className="font-size-5 mb-0 pr-xl-8">
                    <FormattedMessage id="detailed_analytics_text" />
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-xs-8">
              <div
                className="pt-10 pt-lg-18"
                data-aos="fade-up"
                data-aos-delay={600}
              >
                <div className="circle-60 bg-buttercup text-white font-size-7">
                  <i className="fa fa-globe-americas" />
                </div>
                <div className="mt-9">
                  <h4 className="font-size-8 mb-6"><FormattedMessage id="public_campaigns" /></h4>
                  <p className="font-size-5 mb-0 pr-xl-8">
                    <FormattedMessage id="public_campaigns_text" />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-xs-8">
              <div
                className="pt-10 pt-lg-18"
                data-aos="fade-up"
                data-aos-delay={300}
              >
                <div className="circle-60 bg-purple text-white font-size-7">
                  <i className="fa fa-sliders-h" />
                </div>
                <div className="mt-9">
                  <h4 className="font-size-8 mb-6"><FormattedMessage id="customizable_coupons"/></h4>
                  <p className="font-size-5 mb-0 pr-xl-8">
                    <FormattedMessage id="customizable_coupons_text"/>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-xs-8">
              <div
                className="pt-10 pt-lg-18"
                data-aos="fade-up"
                data-aos-delay={500}
              >
                <div className="circle-60 bg-blue-3 text-white font-size-7">
                  <i className="fa fa-camera" />
                </div>
                <div className="mt-9">
                  <h4 className="font-size-8 mb-6"><FormattedMessage id="easy_validation" /></h4>
                  <p className="font-size-5 mb-0 pr-xl-8">
                    <FormattedMessage id="easy_validation_text" />
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-xs-8">
              <div className="pt-10 pt-lg-18">
                <div className="circle-60 bg-dark-green text-white font-size-7">
                  <i className="fa fa-share" />
                </div>
                <div className="mt-9">
                  <h4 className="font-size-8 mb-6"><FormattedMessage id="simple_sharing" /></h4>
                  <p className="font-size-5 mb-0 pr-xl-8">
                    <FormattedMessage id="simple_sharing_text" />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-xs-8">
              <div className="pt-10 pt-lg-18">
                <div className="circle-60 bg-picton-blue-2 text-white font-size-7">
                  <i className="fa fa-layer-group" />
                </div>
                <div className="mt-9">
                  <h4 className="font-size-8 mb-6"><FormattedMessage id="tons_of_backgrounds" /></h4>
                  <p className="font-size-5 mb-0 pr-xl-8">
                    <FormattedMessage id="tons_of_backgrounds_text" />
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-xs-8">
              <div
                className="pt-10 pt-lg-18"
                data-aos="fade-up"
                data-aos-delay={500}
              >
                <div className="circle-60 bg-light-orange text-white font-size-7">
                  <i className="fa fa-info-circle" />
                </div>
                <div className="mt-9">
                  <h4 className="font-size-8 mb-6"><FormattedMessage id="company_details" /></h4>
                  <p className="font-size-5 mb-0 pr-xl-8">
                    <FormattedMessage id="company_details_text" />
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-xs-8">
              <div
                className="pt-10 pt-lg-18"
                data-aos="fade-up"
                data-aos-delay={600}
              >
                <div className="circle-60 bg-dark-cloud text-white font-size-7">
                  <i className="fa fa-comment-dots" />
                </div>
                <div className="mt-9">
                  <h4 className="font-size-8 mb-6"><FormattedMessage id="effortless_referrals" /></h4>
                  <p className="font-size-5 mb-0 pr-xl-8">
                    <FormattedMessage id="effortless_referrals_text" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Services */}
      </div>
    </div>
  );
};

export default Content1;
